.root {
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    /* width: 100%; */
    height:100vh;
    margin: 0;
    padding: 0;
}


.searchBox {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 40px;
    /* display: inline-block; */
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.searchBar {
    width: 600px;
    height: 42px;
    border-radius: 40px;
    box-shadow: 0px 8px 20px rgba(20, 195, 155, 0.06);
    padding-left: 30px;
    margin-top: 20px;
    border: none;
}

.icon {
    position: absolute;
    left: 27.5vw;
    top: 9vh;
}



.sendIcon { 
    position: absolute;
    right: 28vw;
    top: 9.5vh;
    z-index: 100;
}

.button {
    background-color: #558fa2; 
    border: none;
    border-radius: 20px;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin-top: 20px;
    cursor: pointer;
  }
  

.section {
    position: relative;
    top: 300px;
    left: 0;
    
}

.emergingSection {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.emergingText {
    text-align: center;
    color: #6E7B78;
    padding: 12px 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    font-size: 14px;
}


@media only screen and (max-width: 768px) {
   
  }