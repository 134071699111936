body{
    background-color: #f7f8fb;
}

.results {
    padding: 20px;
    margin: 20px
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.searchBar {
    width: 600px;
    height: 42px;
    border-radius: 40px;
    box-shadow: 0px 8px 20px rgba(20, 195, 155, 0.06);
    padding-left: 40px;
    border: 0.5px solid rgb(241, 237, 237);
} 

.searchBar:hover{
    box-shadow: 0px 0px 5px #888888;
}

.sectionHeading {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 5px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-top: 10px;
}

.icon {
    position: absolute;
    left: 20px;
    top: 15px;
}