.recommendedContainer {
    display: flex;
    text-align: center;
}

.card {
    border: 1px dotted;
    border-radius: 5px;
    padding-right: 5px;
    margin-right: 5px;
}

.recommendedContainer:hover {
    cursor: pointer;
}

.applicationTitle {
    padding-left: 5px;
}

.title {
    font-weight: bold;
}

.date {
    font-size: smaller;
    color: #444;
}

.carouselButton {
    display: flex;
    background-color: #999; 
    border-radius: 5px;
    align-items: center;
}

.button {
    background-color: #558fa2; 
    border: none;
    border-radius: 20px;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin-top: 20px;
    cursor: pointer;
  }

  .lastPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}