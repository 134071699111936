.brandLine {
    position:absolute;
    top: 5vh;
    line-height: 40px;
}

.brandName {
    font-weight: 700;
    font-size: 24px;
    left: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 100px;
    /* height: 50px; */
    position: absolute;
    left:0;
    top:0;
}

.logoText{
    position: fixed;
    top: 0;
    right: 0;
    width: 100px;
    font-size: 1rem;
    font-weight: bold;
    top: 20px;
    color: #020b26;
}