.filterData {
    margin: 20px;
}

.heading {
    color: gray;
    text-align: center;
    font-size: 12px;
}

.cardContainer {
    display: flex;
    top: 200px;
}

.card {
    width: 25%;
    text-align: center;
    background-color: white;
    color: black;
    padding: 12px 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
}

.cardTitle {

}

.cardImg {

}

.cardSubTitle {

}

.splitSection{
    top: 120px;
}

.skip {
    color: #020b26;
    position: relative;
    cursor: pointer;
    text-align: center;
    text-decoration:underline;
    text-decoration-style: solid;
    top: 16vh;
}