

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 45px;
  background-color:#f9f9f9;
  color: black;
  text-align: center;
  padding-right: 20px;
}
