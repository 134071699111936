
.header {
  position: absolute;
  left: 45px;
  top: 3vh;
  width: 100px;
  height: 45px;
  background-color:#f9f9f9;
  color: black;
  text-align: left;
  padding-left: 20px;
  padding-top: 20px;
  cursor: pointer;
}