/* @import "../../styles/helpers.scss"; */

.tableTitle {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 24px;

  /* img {
    margin-left: 16px;
  } */
}

.statesTable thead{
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 20px;
}

.transactionTitleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 24px 13px 16px;
}

.widgetContentBlock {
  margin: 0 24px 24px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 13px 24px 13px 16px;
  border-radius: 8px;
  /* background-color: $base-light-gray; */
}

.image {
    width: 32px;
    height: 32px;
}

.checkboxCol {
  width: 5%;
}

.nameCol {
  width: 15%;
}


