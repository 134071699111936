.landingPage {
    /* background-color: #f9f9f9; */
    background-color: #f7f8fb;
    padding: 10px;
    margin: 20px;
    height: 100vh;
}



.section {
    position: relative;
    top: 100px;
    left: 0;
}

.heading{
    text-align: center;
    font-weight: bold;
}

.splitSection {
    position: relative;
    display:-webkit-box;
    top: 120px;
}

.emergingSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 16px;
    
}

.emergingText {
    text-align: center;
    background-color: white;
    color: black;
    padding: 12px 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    font-size: 14px;
    cursor: pointer;
}

.halfScreen {
    width: 49%;
    margin: 10px;
    background-color: white;
}

.sectionHeading {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 5px;
}

.searchRow {
    margin: 10px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    border-bottom: 1px dotted rgba(20, 195, 155, 0.2);
}

.industry {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    width: 50%;
    text-align: right;
}

.query {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    width: 50%;
    align-items: left;
}